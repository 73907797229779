<template>
  <div class="terms">
    <section style="min-height:250px" class="dark--gradient white--text">
      <v-container fluid>
        <v-row align="center" style="height: 300px">
          <v-col align="center">
            <h1 class="display-3">Terms of Use</h1>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-container style="max-width:1200px">
      <v-card class="mt-n10">
        <v-card-text>
          <p class="c4">
            <span class="c8">Date Version: 2020-01-26</span>
          </p>

          <ol class="c3 lst-kix_yhb1t7nvoscs-0 start" start="1">
            <li class="c4">
              <span class="c1">Scope</span>
            </li>
            <li class="c4">
              <span class="c1">Eligibility and Enrollment</span>
            </li>
            <li class="c4">
              <span class="c1">Use of the Service</span>
            </li>
            <li class="c4">
              <span class="c1">Beta Test Customer Responsibilities</span>
            </li>
            <li class="c4">
              <span class="c1">Intellectual Property Ownership</span>
            </li>
            <li class="c4">
              <span class="c1">Account Information and Data</span>
            </li>
            <li class="c4">
              <span class="c1">No Support and Maintenance; Future Products</span>
            </li>
            <li class="c4">
              <span class="c1">Term and Termination</span>
            </li>
            <li class="c4">
              <span class="c1">Disclaimer of Warranties</span>
            </li>
            <li class="c4">
              <span class="c1">Limitation of Liability</span>
            </li>
            <li class="c4">
              <span class="c1">Modification of Terms</span>
            </li>
            <li class="c4">
              <span class="c1">Fees and Costs</span>
            </li>
            <li class="c4">
              <span class="c1">General</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0 start" start="1">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq" style="display:inline">
                <span class="c5">Scope</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >This VentureWallet Beta Tester Agreement, as amended from time to time, (the &quot;Agreement&quot;) is between you or the entity you represent (&quot;Customer&quot;) and Crowdwise, LLC (d/b/a VentureWallet) with its principal place of business at 1 Hardy Road #1107, Bedford, NH 03110 (&quot;Company&quot;).</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="2">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-1" style="display:inline">
                <span class="c5">Eligibility and Enrollment</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >BY CREATING AN ACCOUNT OR OTHERWISE ACCESSING THE SERVICE IN ANY WAY, YOU ACKNOWLEDGE THAT: (1) YOU ARE 18 YEARS OF AGE OR OLDER; AND (2) YOU HAVE READ, UNDERSTOOD, AND ACCEPTED THE TERMS AND CONDITIONS OF THIS AGREEMENT.</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="3">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-2" style="display:inline">
                <span class="c5">Use of the Service</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >Subject to the terms and conditions of this Agreement, Company grants Customer a nonexclusive, nontransferable, nonsublicenseable, revocable, limited license to use Company&rsquo;s software as a service (&ldquo;Service&rdquo;) for a period designated by the Company for the purpose of testing and evaluating the Service (&ldquo;Beta Test&rdquo;). &nbsp;</span>
            </li>
            <li class="c0">
              <span
                class="c1"
              >Customer may not (i) license, sublicense, sell, resell, transfer, assign, distribute, or otherwise commercially exploit or make available to any third party the Service; (ii) modify, alter, tamper with, repair or make derivative works based upon the Service; (iii) create Internet &quot;links&quot; to the Service or &quot;frame&quot; or &quot;mirror&quot; any Service content on any other server or wireless or Internet-based device; (iv) reverse engineer, disassemble, decompile, translate or reproduce the Service; or (v) access the Service in order to (a) build a competitive product or service, (b) build a product using similar ideas, features, functions or graphics of the Service, or (c) copy any ideas, features, functions or graphics of the Service.</span>
            </li>
            <li class="c0">
              <span
                class="c1"
              >Customer may use the Service only as part of, and in connection with, the Beta Test and shall not: (i) send spam or otherwise duplicative or unsolicited messages in violation of applicable laws; (ii) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or violative of third party privacy rights; (iii) send or store material containing software viruses, worms, Trojan horses, or other harmful computer code, files, scripts, agents, or programs; (iv) interfere with or disrupt the integrity or performance of the Service or the data contained therein; or (v) attempt to gain unauthorized access to the Service or its related systems or networks.</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="4">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-3" style="display:inline">
                <span class="c5">Beta Test Customer Responsibilities</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >Customer agrees to report any flaws, errors or imperfections discovered in any software or other materials where Customer has been granted access to the Service. Customer agrees to provide all feedback and feature requests using the provided communications channels. Customer understands that prompt and accurate reporting is the purpose of the Beta Test and undertakes to use best efforts to provide frequent reports on all aspects of the product both positive and negative and acknowledges that any improvements, modifications and changes arising from or in connection with the Customer&rsquo;s contribution to the Beta Test, remain or become the exclusive property of Company.</span>
            </li>
            <li class="c0">
              <span
                class="c1"
              >Customer is responsible for all activity occurring under Customer&#39;s user accounts and shall abide by all applicable local, state, national, and foreign, laws, treaties and regulations in connection with Customer&#39;s use of the Service, including those related to data privacy, data security and the transmission of technical or personal data.</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="5">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-4" style="display:inline">
                <span class="c5">Intellectual Property Ownership</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >Ownership and Copyright of the Service remain with Company. The Service is copyrighted and is protected by United States copyright laws and international treaty provisions. Customer will not remove copyright notices from the Service. Customer agrees to prevent any unauthorized copying of the Service. Except as expressly provided herein, Company does not grant any express or implied right to Customer under Company patents, copyrights, trademarks, or trade secret information.</span>
            </li>
            <li class="c0">
              <span
                class="c1"
              >Customer shall not make, have made, use or sell for any purpose any product or other item using, incorporating or derived from the Service. &nbsp;The Customer will not modify, reverse engineer, decompile, create other works from, or disassemble any software programs contained in the Service.</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="6">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-5" style="display:inline">
                <span class="c5">Account Information and Data</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span>&ldquo;</span>
              <span class="c6">Customer Data</span>
              <span
                class="c1"
              >&rdquo; is any data, information, content or material provided, input, uploaded or submitted by Customer to the Service. Customer consents to Company&rsquo;s use of Customer Data to provide the Service to Customer and Company&rsquo;s use of aggregated Customer Data for marketing and data analysis purposes. Customer, not Company, shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all Customer Data, and Company shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any Customer Data.</span>
            </li>
            <li class="c0">
              <span
                class="c1"
              >Company may disclose Customer Data to provide the Service to Customer or to comply with any request of a governmental or regulatory body (including subpoenas or court orders). Company may disclose aggregated and anonymized Customer Data for marketing and data analysis purposes. Company may access or store personal information in multiple countries, including countries outside of your own country to the extent permitted by applicable law.</span>
            </li>
            <li class="c0">
              <span
                class="c1"
              >Upon termination, Customer&#39;s right to access or use Customer Data through the Service immediately ceases, and Company shall have no obligation to maintain or forward any Customer Data and may delete such Customer Data immediately upon termination. Any post-termination data retrieval assistance from Company is subject to agreement by Company and Customer.</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="7">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-6" style="display:inline">
                <span class="c5">No Support and Maintenance; Future Products</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >During Customer&rsquo;s participation in the Beta Test, Company is not obligated to provide any maintenance, technical or other support for the Service. If at Company&rsquo;s option such support is provided, Customer agrees to abide by any support rules and policies that Company provides in order to receive such support. Customer acknowledges that Company has no express or implied obligation to announce or make available a commercial version of the Service to anyone in the future. Should a commercial version be made available, it may have features or functionality that are different from those found in the Service licensed hereunder.</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="8">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-7" style="display:inline">
                <span class="c5">Term and Termination</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >Unless otherwise terminated as specified under this Agreement, Customer&rsquo;s rights with respect to the Service will terminate upon the earlier of (a) the initial commercial release by Company of a generally available version of the Service or (b) an announced date when Company either deems sufficient feedback has been received or decides to terminate the Beta Test for any other reason. Either party may terminate this Agreement at any time for any reason or no reason without any prior written notice. If Company elects to end the Beta Test prior to the release of a commercially available Service, Company will make best effort to provide sufficient notice to Customer at least 7 days in advance of termination to allow Customer to transition any data from the Service.</span>
            </li>
            <li class="c0">
              <span
                class="c1"
              >Company shall immediately terminate this Agreement and any Customer rights with respect to the Beta Test without notice in the event of improper use of Service as specified under this Agreement. Upon any expiration or termination of this Agreement, the rights and licenses granted to Customer under this Agreement shall immediately terminate, and Customer shall immediately cease using the Service. The rights and obligations of the parties set forth in this Agreement shall survive termination or expiration of this Agreement for any reason.</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="9">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-8" style="display:inline">
                <span class="c5">Disclaimer of Warranties</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >This Service is a beta release offering and is not at the level of performance of a commercially available product offering. &nbsp;The Service may not operate correctly and may be substantially modified prior to first commercial release, or at Company&rsquo;s option may not be released commercially in the future. &nbsp;</span>
            </li>
            <li class="c0">
              <span
                class="c1"
              >THE SERVICE AND DOCUMENTATION ARE PROVIDED &ldquo;AS IS&rdquo; WITHOUT WARRANTY OF ANY KIND, AND COMPANY AND ITS LICENSORS DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. &nbsp;NO ORAL OR WRITTEN ADVICE OR CONSULTATION GIVEN BY COMPANY, ITS AGENTS OR EMPLOYEES WILL IN ANY WAY GIVE RISE TO A WARRANTY. &nbsp;THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE SERVICE REMAINS WITH CUSTOMER.</span>
            </li>
            <li class="c0">
              <span
                class="c1"
              >CUSTOMER ACKNOWLEDGES AND AGREES THAT: (A) THE SERVICES ARE NOT IN FINAL FORM, HAVE NOT BEEN RELEASED FOR SALE BY THE COMPANY AND MAY CONTAIN DEFECTS, ERRORS, DESIGN FLAWS OR OTHER PROBLEMS WHICH CANNOT OR WILL NOT BE CORRECTED; (B) THE USE OF THE SERVICES MAY NOT BE SECURE, TIMELY, UNINTERRUPTED, OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM, OR DATA; (C) ANY STORED DATA MAY NOT BE ACCURATE OR RELIABLE; (D) THE SERVICES OR THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY CUSTOMER THROUGH THE SERVICES MAY NOT MEET CUSTOMER&#39;S REQUIREMENTS OR EXPECTATIONS; (E) THE SERVICES OR THE SERVER(S) THAT MAKE THE SERVICES AVAILABLE MAY NOT BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (F) THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS.</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="10">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-9" style="display:inline">
                <span class="c5">Limitation of Liability</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >COMPANY AND ITS LICENSORS SHALL NOT BE LIABLE FOR LOSS OF USE, LOST PROFIT, COST OF COVER, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO THE SERVICE OR THIS AGREEMENT, HOWEVER CAUSED AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) STRICT LIABILITY, OR OTHERWISE, EVEN IF SUCH PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. &nbsp;</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="11">
            <li class="c2">
              <h1 id="h.urxw53k6rxls" style="display:inline">
                <span class="c5">Modification to Terms</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >Company reserves the right to modify the terms and conditions of this Agreement or its policies relating to the Service at any time, effective upon posting of an updated version of this Agreement on the Service. Customer is responsible for regularly reviewing this Agreement. Continued use of the Service after any such changes shall constitute Customer&#39;s consent to such changes.</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="12">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-10" style="display:inline">
                <span class="c5">Fees and Costs</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >There are no license fees for Customer&rsquo;s use of the Service under this Agreement. Customer is responsible for all costs and expenses associated with the use of the Service and the performance of all testing and evaluation activities.</span>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-0" start="13">
            <li class="c2">
              <h1 id="h.nf5lcntznqdq-11" style="display:inline">
                <span class="c5">General</span>
              </h1>
            </li>
          </ol>
          <ol class="c3 lst-kix_xzdpbn70wsqc-1 start" start="1">
            <li class="c0">
              <span
                class="c1"
              >The Customer&rsquo;s obligations under this Agreement shall survive any termination of this agreement. This Agreement shall be governed by and construed in accordance with the laws of New Hampshire. The Customer hereby agrees that breach of this Agreement will cause Company irreparable damage for which recovery of damages would be inadequate, and that the Company shall therefore be entitled to obtain timely injunctive relief under this Agreement, as well as such further relief as may be granted by a court of competent jurisdiction. &nbsp;The Customer will not assign or transfer any rights or obligations under this Agreement without the prior written consent of the Company.</span>
            </li>
          </ol>
          <p class="c9 c10">
            <span class="c1"></span>
          </p>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped type="text/css">
@import url("https://themes.googleusercontent.com/fonts/css?kit=6ozZp4BPlrbDRWPe3EBGAx34v_TTTPSJiLrAm2E2VUrenU4zKVVosfiWjEvcKn3r");
.lst-kix_yhb1t7nvoscs-8 > li {
  counter-increment: lst-ctn-kix_yhb1t7nvoscs-8;
}
ol.lst-kix_xzdpbn70wsqc-6.start {
  counter-reset: lst-ctn-kix_xzdpbn70wsqc-6 0;
}
.lst-kix_yhb1t7nvoscs-0 > li:before {
  content: "" counter(lst-ctn-kix_yhb1t7nvoscs-0, decimal) ". ";
}
.lst-kix_xzdpbn70wsqc-5 > li {
  counter-increment: lst-ctn-kix_xzdpbn70wsqc-5;
}
ol.lst-kix_yhb1t7nvoscs-8.start {
  counter-reset: lst-ctn-kix_yhb1t7nvoscs-8 0;
}
.lst-kix_yhb1t7nvoscs-2 > li {
  counter-increment: lst-ctn-kix_yhb1t7nvoscs-2;
}
ol.lst-kix_yhb1t7nvoscs-5.start {
  counter-reset: lst-ctn-kix_yhb1t7nvoscs-5 0;
}
ol.lst-kix_xzdpbn70wsqc-8 {
  list-style-type: none;
}
ol.lst-kix_xzdpbn70wsqc-5.start {
  counter-reset: lst-ctn-kix_xzdpbn70wsqc-5 0;
}
ol.lst-kix_xzdpbn70wsqc-7 {
  list-style-type: none;
}
.lst-kix_xzdpbn70wsqc-1 > li:before {
  content: "" counter(lst-ctn-kix_xzdpbn70wsqc-0, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-1, decimal) ". ";
}
.lst-kix_xzdpbn70wsqc-3 > li:before {
  content: "" counter(lst-ctn-kix_xzdpbn70wsqc-0, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-1, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-2, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-3, decimal) ". ";
}
ol.lst-kix_xzdpbn70wsqc-6 {
  list-style-type: none;
}
.lst-kix_xzdpbn70wsqc-2 > li:before {
  content: "" counter(lst-ctn-kix_xzdpbn70wsqc-0, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-1, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-2, decimal) ". ";
}
ol.lst-kix_yhb1t7nvoscs-6.start {
  counter-reset: lst-ctn-kix_yhb1t7nvoscs-6 0;
}
.lst-kix_xzdpbn70wsqc-5 > li:before {
  content: "" counter(lst-ctn-kix_xzdpbn70wsqc-0, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-1, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-2, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-3, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-4, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-5, decimal) ". ";
}
ol.lst-kix_yhb1t7nvoscs-2.start {
  counter-reset: lst-ctn-kix_yhb1t7nvoscs-2 0;
}
ol.lst-kix_xzdpbn70wsqc-2.start {
  counter-reset: lst-ctn-kix_xzdpbn70wsqc-2 0;
}
.lst-kix_xzdpbn70wsqc-4 > li:before {
  content: "" counter(lst-ctn-kix_xzdpbn70wsqc-0, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-1, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-2, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-3, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-4, decimal) ". ";
}
ol.lst-kix_xzdpbn70wsqc-8.start {
  counter-reset: lst-ctn-kix_xzdpbn70wsqc-8 0;
}
.lst-kix_yhb1t7nvoscs-4 > li {
  counter-increment: lst-ctn-kix_yhb1t7nvoscs-4;
}
.lst-kix_yhb1t7nvoscs-1 > li {
  counter-increment: lst-ctn-kix_yhb1t7nvoscs-1;
}
.lst-kix_xzdpbn70wsqc-4 > li {
  counter-increment: lst-ctn-kix_xzdpbn70wsqc-4;
}
.lst-kix_xzdpbn70wsqc-1 > li {
  counter-increment: lst-ctn-kix_xzdpbn70wsqc-1;
}
.lst-kix_xzdpbn70wsqc-7 > li {
  counter-increment: lst-ctn-kix_xzdpbn70wsqc-7;
}
.lst-kix_yhb1t7nvoscs-7 > li {
  counter-increment: lst-ctn-kix_yhb1t7nvoscs-7;
}
.lst-kix_xzdpbn70wsqc-0 > li:before {
  content: "" counter(lst-ctn-kix_xzdpbn70wsqc-0, decimal) ". ";
}
ol.lst-kix_yhb1t7nvoscs-3.start {
  counter-reset: lst-ctn-kix_yhb1t7nvoscs-3 0;
}
.lst-kix_xzdpbn70wsqc-8 > li {
  counter-increment: lst-ctn-kix_xzdpbn70wsqc-8;
}
.lst-kix_xzdpbn70wsqc-2 > li {
  counter-increment: lst-ctn-kix_xzdpbn70wsqc-2;
}
.lst-kix_yhb1t7nvoscs-5 > li {
  counter-increment: lst-ctn-kix_yhb1t7nvoscs-5;
}
ol.lst-kix_xzdpbn70wsqc-1.start {
  counter-reset: lst-ctn-kix_xzdpbn70wsqc-1 0;
}
.lst-kix_xzdpbn70wsqc-6 > li:before {
  content: "" counter(lst-ctn-kix_xzdpbn70wsqc-0, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-1, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-2, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-3, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-4, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-5, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-6, decimal) ". ";
}
ol.lst-kix_xzdpbn70wsqc-1 {
  list-style-type: none;
}
ol.lst-kix_xzdpbn70wsqc-0 {
  list-style-type: none;
}
ol.lst-kix_xzdpbn70wsqc-4.start {
  counter-reset: lst-ctn-kix_xzdpbn70wsqc-4 0;
}
.lst-kix_xzdpbn70wsqc-7 > li:before {
  content: "" counter(lst-ctn-kix_xzdpbn70wsqc-0, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-1, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-2, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-3, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-4, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-5, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-6, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-7, decimal) ". ";
}
ol.lst-kix_xzdpbn70wsqc-5 {
  list-style-type: none;
}
ol.lst-kix_xzdpbn70wsqc-4 {
  list-style-type: none;
}
ol.lst-kix_xzdpbn70wsqc-3 {
  list-style-type: none;
}
ol.lst-kix_yhb1t7nvoscs-0.start {
  counter-reset: lst-ctn-kix_yhb1t7nvoscs-0 0;
}
ol.lst-kix_xzdpbn70wsqc-2 {
  list-style-type: none;
}
.lst-kix_xzdpbn70wsqc-8 > li:before {
  content: "" counter(lst-ctn-kix_xzdpbn70wsqc-0, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-1, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-2, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-3, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-4, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-5, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-6, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-7, decimal) "."
    counter(lst-ctn-kix_xzdpbn70wsqc-8, decimal) ". ";
}
ol.lst-kix_yhb1t7nvoscs-4.start {
  counter-reset: lst-ctn-kix_yhb1t7nvoscs-4 0;
}
ol.lst-kix_yhb1t7nvoscs-7 {
  list-style-type: none;
}
.lst-kix_xzdpbn70wsqc-0 > li {
  counter-increment: lst-ctn-kix_xzdpbn70wsqc-0;
}
ol.lst-kix_yhb1t7nvoscs-6 {
  list-style-type: none;
}
.lst-kix_xzdpbn70wsqc-3 > li {
  counter-increment: lst-ctn-kix_xzdpbn70wsqc-3;
}
.lst-kix_yhb1t7nvoscs-6 > li:before {
  content: "" counter(lst-ctn-kix_yhb1t7nvoscs-6, decimal) ". ";
}
.lst-kix_yhb1t7nvoscs-6 > li {
  counter-increment: lst-ctn-kix_yhb1t7nvoscs-6;
}
.lst-kix_yhb1t7nvoscs-8 > li:before {
  content: "" counter(lst-ctn-kix_yhb1t7nvoscs-8, lower-roman) ". ";
}
ol.lst-kix_yhb1t7nvoscs-8 {
  list-style-type: none;
}
.lst-kix_yhb1t7nvoscs-0 > li {
  counter-increment: lst-ctn-kix_yhb1t7nvoscs-0;
}
ol.lst-kix_yhb1t7nvoscs-3 {
  list-style-type: none;
}
.lst-kix_xzdpbn70wsqc-6 > li {
  counter-increment: lst-ctn-kix_xzdpbn70wsqc-6;
}
ol.lst-kix_yhb1t7nvoscs-2 {
  list-style-type: none;
}
ol.lst-kix_yhb1t7nvoscs-5 {
  list-style-type: none;
}
ol.lst-kix_yhb1t7nvoscs-4 {
  list-style-type: none;
}
.lst-kix_yhb1t7nvoscs-7 > li:before {
  content: "" counter(lst-ctn-kix_yhb1t7nvoscs-7, lower-latin) ". ";
}
ol.lst-kix_yhb1t7nvoscs-1 {
  list-style-type: none;
}
ol.lst-kix_yhb1t7nvoscs-0 {
  list-style-type: none;
}
.lst-kix_yhb1t7nvoscs-2 > li:before {
  content: "" counter(lst-ctn-kix_yhb1t7nvoscs-2, lower-roman) ". ";
}
ol.lst-kix_xzdpbn70wsqc-7.start {
  counter-reset: lst-ctn-kix_xzdpbn70wsqc-7 0;
}
.lst-kix_yhb1t7nvoscs-1 > li:before {
  content: "" counter(lst-ctn-kix_yhb1t7nvoscs-1, lower-latin) ". ";
}
.lst-kix_yhb1t7nvoscs-3 > li {
  counter-increment: lst-ctn-kix_yhb1t7nvoscs-3;
}
ol.lst-kix_yhb1t7nvoscs-1.start {
  counter-reset: lst-ctn-kix_yhb1t7nvoscs-1 0;
}
ol.lst-kix_yhb1t7nvoscs-7.start {
  counter-reset: lst-ctn-kix_yhb1t7nvoscs-7 0;
}
ol.lst-kix_xzdpbn70wsqc-3.start {
  counter-reset: lst-ctn-kix_xzdpbn70wsqc-3 0;
}
.lst-kix_yhb1t7nvoscs-3 > li:before {
  content: "" counter(lst-ctn-kix_yhb1t7nvoscs-3, decimal) ". ";
}
.lst-kix_yhb1t7nvoscs-4 > li:before {
  content: "" counter(lst-ctn-kix_yhb1t7nvoscs-4, lower-latin) ". ";
}
ol.lst-kix_xzdpbn70wsqc-0.start {
  counter-reset: lst-ctn-kix_xzdpbn70wsqc-0 0;
}
.lst-kix_yhb1t7nvoscs-5 > li:before {
  content: "" counter(lst-ctn-kix_yhb1t7nvoscs-5, lower-roman) ". ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c4 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c2 {
  margin-left: 36pt;
  padding-top: 36pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c0 {
  margin-left: 72pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c5 {
  color: #424242;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 18pt;
  font-family: "Oswald";
  font-style: normal;
}
.c8 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-family: "Oswald";
  font-style: normal;
}
.c1 {
  color: #424242;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c9 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c6 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}
.c7 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 45pt 72pt 72pt 72pt;
}
.c3 {
  padding: 0;
  margin: 0;
}
.c10 {
  height: 11pt;
}
.title {
  padding-top: 10pt;
  color: #424242;
  font-size: 52pt;
  padding-bottom: 0pt;
  font-family: "Oswald";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 0pt;
  font-family: "Covered By Your Grace";
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: center;
}
li {
  color: #424242;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #424242;
  font-size: 11pt;
  font-family: "Arial";
}
h2 {
  padding-top: 0pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 0pt;
  font-family: "Oswald";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
h3 {
  padding-top: 0pt;
  color: #e31c60;
  font-size: 10pt;
  padding-bottom: 0pt;
  font-family: "Arial";
  line-height: 1.3;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 8pt;
  -webkit-text-decoration-skip: none;
  color: #666666;
  text-decoration: underline;
  font-size: 11pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  page-break-after: avoid;
  text-decoration-skip-ink: none;
  font-family: "Trebuchet MS";
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 8pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Trebuchet MS";
  line-height: 1.3;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 8pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Trebuchet MS";
  line-height: 1.3;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>